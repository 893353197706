<template>
    <div ref="whatwillmyinvestmentsbeworthchart" class="investment-worth-chart"></div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {ref, watch, toRef, onMounted, computed} from 'vue'
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    export default {
      name: 'WhatWillMyInvestmentsBeWorthChart',
      props: {
        chartData: Object,
        barWidth: Number
      },
      setup(props) {

        const series = ref([]);
        const xAxis = ref([]);
        const chartRendered = ref(false);
        const whatwillmyinvestmentsbeworthchart = ref(null);
        const exporting = ref();

        const chartData = toRef(props, 'chartData')
        const barWidth = toRef(props, 'barWidth')

        const tidiedChartData = computed(() => {
          let negative = false;
          chartData.value.forEach((row) => {
              if(row.growthValue < 0){
                  negative = true;
              }
          })

          if(negative){
              return chartData.value.map((record) => {
                  return {
                      "year": record.year, "balance": record.balance+record.growthValue, "savingsAmount": 0, "growthValue": 0
                  };
              })
          }else{
              return chartData.value;
          }
        })

        onMounted(() => {
          if(chartData.value) {
            renderChart(series, tidiedChartData.value)
            chartRendered.value = true;
          }
        })

        watch(tidiedChartData, (value) => {
          if(chartRendered.value){
            updateChart(series, value)
          }else{
            renderChart(series, value)
            chartRendered.value = true;
          }
        })

        const renderChart = (series, data) => {

          am5.addLicense("AM5C334338446");

          let root = am5.Root.new(whatwillmyinvestmentsbeworthchart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          const numberFormat = {
            numberFormat: "#.#a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
              { "number": 1e+3, "suffix": "K" },
              { "number": 1e+6, "suffix": "M" },
              { "number": 1e+9, "suffix": "B" }
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: []
          }

          root.numberFormatter.setAll(numberFormat);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout
          }));

          chart.get("colors").set("colors", [
            am5.color("#179BD7"),
            am5.color("#F18903")
          ]);

          const linearGradient = am5.LinearGradient.new(root, {
            stops: [{
              opacity: 1
            }, {
              opacity: 0.2
            }],
            rotation: 90
          })

          const linearGradientGrowth = am5.LinearGradient.new(root, {
            stops: [{
              opacity: 1
            }, {
              opacity: 0.0
            }],
            rotation: 90
          })

          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          xAxis.value = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "year",
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 30
            }),
            tooltip: am5.Tooltip.new(root, {}),
          }));

          xAxis.value.data.setAll(data);

          var yRenderer = am5xy.AxisRendererY.new(root, {})

          var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            calculateTotals: true,
            renderer: yRenderer,
            //spacing at the top of the chart
            extraMax: 0.1,
          }));

          //yAxis.set("numberFormatter", am5.NumberFormatter.new(root, numberFormat));

          var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.percent(56),
          }));

          legend.labels.template.setAll({
            fontSize: 14,
            fontWeight: "400",
            marginLeft: 18,
            centerY: 6
          });

          legend.markerRectangles.template.setAll({
            cornerRadiusTL: 15,
            cornerRadiusTR: 15,
            cornerRadiusBL: 15,
            cornerRadiusBR: 15,
            width: 25,
            height: 25
          });

          // Add series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          function makeSeries(name, fieldName) {
            series.value[fieldName] = chart.series.push(am5xy.ColumnSeries.new(root, {
              name: name,
              xAxis: xAxis.value,
              yAxis: yAxis,
              valueYField: fieldName,
              categoryXField: "year",
              stacked: true,
            }));

            if(fieldName === 'growthValue') {
              series.value[fieldName].columns.template.setAll({
                tooltipText: "{name}: {valueY}\nTotal: {valueYTotal}",
                width: am5.percent(95),
                tooltipY: 0,
                cornerRadiusTL: barWidth.value/2,
                cornerRadiusTR: barWidth.value/2,
                maxWidth: barWidth.value,
              });
            } else {
              series.value[fieldName].columns.template.setAll({
                tooltipText: "{name}: {valueY}\nTotal: {valueYTotal}",
                width: am5.percent(95),
                dy: -5,
                tooltipY: 0,
                cornerRadiusTL: barWidth.value/2,
                cornerRadiusTR: barWidth.value/2,
                maxWidth: barWidth.value,
              });
          }

            if(fieldName === 'balance') {
              series.value[fieldName].columns.template.set("fillGradient", linearGradient);
            }
            if(fieldName === 'growthValue') {
              series.value[fieldName].columns.template.set("fillGradient", linearGradientGrowth);
            }

            series.value[fieldName].columns.template.set("strokeOpacity", 0);
            series.value[fieldName].data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.value[fieldName].appear();
            /*
            if(fieldName === 'growthValue') {
              series.value[fieldName].bullets.push(function () {
                return am5.Bullet.new(root, {
                  locationY: 1,
                  sprite: am5.Label.new(root, {
                    text: "{valueYTotal}",
                    centerX: am5.p50,
                    centerY: am5.p100,
                    populateText: true
                  })
                });
              });
            }
            */
            legend.data.push(series.value[fieldName]);
          }

          makeSeries('Total Invested', 'balance');
          makeSeries('Growth Value','growthValue');

          // Make stuff animate on load
          // https://www.amcharts.com/docs/v5/concepts/animations/
          chart.appear(1000, 100);

          exporting.value = am5plugins_exporting.Exporting.new(root, {});
        }

        const updateChart = (series, data) => {
          xAxis.value.data.setAll(data);
          series.value['balance'].data.setAll(data);
          series.value['growthValue'].data.setAll(data);
        }

        return {
          series,
          chartRendered,
          whatwillmyinvestmentsbeworthchart,
          tidiedChartData
        }
      }
    }
</script>

<style scoped lang="scss">
    .investment-worth-chart {
        position: relative;
        width: 100%;
        height: 700px;
    }
</style>
