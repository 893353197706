<template>
    <Modal id="loadPortfolioValuesModal" :width="`60%`">
        <template #body>
            <ContentCardFull>
                <template #title>
                    <div class="flex flex-column title">
                        <div class="flex justify-content-between align-items-center">
                            <h2>Load Portfolio Values</h2>
                            <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span></Button>
                        </div>
                    </div>
                </template>
                <template #content-full>
                    <div class="flex flex-column" style="width: 100%">
                        <h3>Total Value Selected: <currency-value :value="totalValueSelected" :decimal-places="2" /></h3>
                        <div class="flex-auto">
                          <ScrollPanel style="width: 100%; height: 50vh" class="pr-3">
                            <portfolio-selection :exclude-types="excludeTypes" v-model:selectedPolicyValue="selectedPolicy" v-model:selectedPlanValue="selectedPlan" @update-total-value="updateTotalValue"  />
                          </ScrollPanel>
                        </div>
                    </div>
                    <div class="flex justify-content-between my-3" style="width: 100%">
                        <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
                        <Button class="clarity-btn clarity-gradient-orange" @click="setTotalValue" >Load Values</Button>
                    </div>
                </template>
            </ContentCardFull>
        </template>
    </Modal>
</template>
<script>
import { useStore } from 'vuex'
import Modal from '@/components/common/Modal'
import ContentCardFull from '@/components/common/ContentCardFull'
import PortfolioSelection from "@/components/portfolios/PortfolioSelection";
import {ref} from "vue";
import CurrencyValue from "../common/CurrencyValue";

export default {
    setup(props, { emit }) {
        const store = useStore()

        const selectedPolicy = ref([])
        const selectedPlan = ref([])
        const totalValueSelected = ref(0)
        const excludeTypes = ['Pension'];

        const closeModal = () => {
            resetModal()
            store.dispatch('setActiveModal')
        }
        const resetModal = () => {
            selectedPlan.value = [];
            selectedPolicy.value = [];
        }
        const updateTotalValue = (value) => {
          totalValueSelected.value = value;
        }

        const setTotalValue = () => {
          emit('set-value', totalValueSelected.value)
          closeModal()
        }

        return {
            closeModal,
            resetModal,
            selectedPolicy,
            selectedPlan,
            totalValueSelected,
            updateTotalValue,
            excludeTypes,
            setTotalValue
        }
    },
    components: {
      CurrencyValue,
        Modal,
        ContentCardFull,
        PortfolioSelection
    }
}
</script>
<style scoped>
.title {
  padding: 30px;
  padding-bottom: 0px;
}
div:deep(.modal-close) {
    display: none;
}
.card {
    border-radius: 27px;
    border: 1px solid var(--clarity-light-grey);
    box-shadow: none;
}
.portfolio-item {
    width: 50%;
}
.portfolio-checkbox {
    display: flex;
    width: 60%;
    padding-top: 5px;
    padding-bottom: 5px;
}
div:deep(.p-checkbox) {
    margin-right: 15px;
    border-radius: 2px;
}
div:deep(.p-checkbox-box) {
    border-radius: 0px;
}
.filters {
    width: 100%;
}
.filters > div {
    width: 50%;
}
.filters label {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
}
.overview {
    font-size: 18px;
}
.creation-summary {
    padding-left: 30px;
}
.creation-summary label {
    font-weight: bold;
    margin-right: 5px;
}
.selected-label {
  color: var(--clarity-blue);
  font-weight: bold;
}
button.clarity-btn {
  padding: 15px 45px;
}
</style>
