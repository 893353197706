<template>
  <div>
      <div class="flex flex-column">
        <h3 class="mb-3">How much do I need to invest?</h3>
        <div class="flex align-items-center justify-content-between">
          <div class="mr-5">
            <div class="card-body">If you have an investment goal in mind then our calculator will tell you how much you need to save per month on top of any existing savings.</div>
          </div>
        </div>
      </div>

      <spacer-h />

      <div class="flex">
        <div class="flex-1 mr-3">
          <h3 class="mb-3">Investment Target</h3>
          <div class="round-wrapper">
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Target Investment Amount:</h5>
                  <p class="small-text grey">Enter your target investment value. The calculator will work out the required savings to meet this goal.</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
                <InputNumber @focus="$event.target.select()" v-model="targetAmount" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 100%" />
              </div>
            </div>
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Target Investment Terms:</h5>
                  <p class="small-text grey">See the results in nominal terms, or in real terms, allowing for the effect of inflation.</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper" >
                <Dropdown v-model="targetTerms" :options="termsOptions" optionLabel="name" option-value="value" class="calc-input" style="width: 100%"/>
              </div>
            </div>
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Current Value of Funds:</h5>
                  <p class="small-text grey">Enter the value of any existing investments you would like to be included in the calculation.</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
                <InputNumber @focus="$event.target.select()" v-model="currentValue" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 100%" />
              </div>
            </div>
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Investment Timeframe:</h5>
                  <p class="small-text grey">The length of time that you plan to invest for your investments (express in years).</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
                <InputNumber v-model="investmentTimeframe" :min="1" suffix=" years" show-buttons class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
              </div>
            </div>
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Payment Frequency:</h5>
                  <p class="small-text grey">Choose the frequency for your payments (Weekly, Monthly or Annual).</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
                <Dropdown v-model="paymentFrequency" :options="frequencyOptions" optionLabel="name" option-value="value" style="width: 100%"/>
              </div>
            </div>
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Growth Rate Assumption:</h5>
                  <p class="small-text grey">Percentage that your investments are assumed to grow by each year net of charges. Default assumption is 4.5%.</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
                <InputNumber @focus="$event.target.select()" v-model="growthRateAssumption" mode="decimal" :min="-50" :step="0.25" :minFractionDigits="2" :maxFractionDigits="2" suffix="%" showButtons class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
              </div>
            </div>
            <div class="flex align-items-stretch">
              <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Inflation Rate Assumption:</h5>
                  <p class="small-text grey">Used to discount the projected value back to Today’s Terms. Default Assumption is 2.5%.</p>
                </div>
              </div>
              <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
                <InputNumber @focus="$event.target.select()" v-model="inflationRateAssumption" mode="decimal" :min="-50" :step="0.25" :minFractionDigits="2" :maxFractionDigits="2" suffix="%" showButtons class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 ml-3">
          <h3 class="mb-3">Results</h3>
          <div class="round-wrapper results">
            <div class="flex">
              <div class="flex-1 align-items-center mr-3" align="center">
                <h5 class="dark-grey">Regular Savings Amount:</h5>
                <h3 class="blue" align="center"><currency-value :value="regularSavingsAmount" :decimal-places="2" /> <span class="small-text" style="text-transform: none;">per month</span></h3>
                <p class="small-text grey">Value required to meet your target</p>
              </div>
              <div class="flex-1 align-items-center ml-3" align="center">
                <h5 class="dark-grey">Savings Goal:</h5>
                <h3 class="blue" align="center"><currency-value :value="targetAmount" :decimal-places="2" /></h3>
                <p class="small-text grey">Total Value at end of investment time frame selected</p>
              </div>
            </div>
            <div>
              <div v-if="targetAmount > 0">
                <how-much-do-i-need-to-invest-chart :chart-data="investmentYears" :bar-width="40" />
              </div>
              <div v-else>
                <Skeleton width="100%" height="600px" animation="none" />
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import SpacerH from '@/components/common/layout/SpacerH.vue'
import { computed, ref} from "vue";
import CurrencyValue from "../common/CurrencyValue";
import HowMuchDoINeedToInvestChart from "../charts/HowMuchDoINeedToInvestChart";
import {useStore} from "vuex";


export default {
  name: "HowMuchDoINeedToInvest",
  setup() {

    const store = useStore()

    const targetAmount = ref(store.state.calculators.startingValue);
    const currentValue = ref(store.state.calculators.startingValue);
    const targetTerms = ref(0);
    const investmentTimeframe = ref(store.state.calculators.projectionYears);
    const paymentFrequency = ref(12)
    const growthRateAssumption = ref(store.state.calculators.growthRate)
    const inflationRateAssumption = ref(store.state.calculators.inflationRate)
    const userCurrencyValue = ref(store.getters.currencySelected);

    const termsOptions = [
      {name: "Today's Terms", value: 0},
      {name: "Tomorrow's Terms", value: 1},
    ]

    const frequencyOptions = [
      {name: "Weekly", value: 52},
      {name: "Monthly", value: 12},
      {name: "Annual", value: 1},
    ]

    const regularSavingsAmount = computed(() => {
      // =IF(targetTerms=0,targetAmount*((1+inflationRateAssumption)^investmentTimeframe),targetAmount)
      const TargetInvestment = (targetTerms.value ? targetAmount.value : (targetAmount.value*(Math.pow((1+(inflationRateAssumption.value/100)),investmentTimeframe.value))));
      const CurrentValue = currentValue.value;
      const GrowthRate = growthRateAssumption.value/100;
      const InvestmentTimeframe = investmentTimeframe.value;
      const PaymentFrequency = paymentFrequency.value;
      const BottomFraction = Math.pow(PaymentFrequency, 2);
      // (PaymentFrequency^2+PaymentFrequency)/2
      const TopFraction = (Math.pow(PaymentFrequency,2)+PaymentFrequency)/2;

      const returnValue = (((TargetInvestment-(CurrentValue*(Math.pow((1+GrowthRate),InvestmentTimeframe))))*GrowthRate)/(((Math.pow((1+GrowthRate),InvestmentTimeframe))-1)*(1+((GrowthRate/BottomFraction)*TopFraction))))/PaymentFrequency;

      return returnValue;
    })

    const investmentYears = computed( () => {

      const InvestmentTimeframe = investmentTimeframe.value;
      const GrowthRate = growthRateAssumption.value/100;
      const PerfectPremium = regularSavingsAmount.value;
      const PaymentFrequency = paymentFrequency.value;
      const BottomFraction = Math.pow(PaymentFrequency, 2);
      // (PaymentFrequency^2+PaymentFrequency)/2
      const TopFraction = (Math.pow(PaymentFrequency,2)+PaymentFrequency)/2;

      const years = [];
      const CurrentValue = currentValue.value;
      for (let i = 1; i <= InvestmentTimeframe; i++) {
        const value = (CurrentValue*(Math.pow((1+GrowthRate),i)))+(((PerfectPremium*PaymentFrequency)*(1+((GrowthRate/BottomFraction)*TopFraction))*((Math.pow((1+GrowthRate),i))-1))/GrowthRate)
        years.push({ year: i, balance: value});
      }
      return years;
    })

    return {
      targetAmount,
      currentValue,
      targetTerms,
      investmentTimeframe,
      paymentFrequency,
      growthRateAssumption,
      inflationRateAssumption,
      termsOptions,
      frequencyOptions,
      regularSavingsAmount,
      investmentYears,
      userCurrencyValue
    }
  },
  components: {
    HowMuchDoINeedToInvestChart,
    CurrencyValue,
    SpacerH
  }
}
</script>

<style scoped>
  .round-wrapper {
    min-height: 900px;
    background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
    border-radius: 27px;
    opacity: 1;
    padding: 30px;
  }
  .input-wrapper {
    width: 200px;
  }
  .results h3 {
    text-align: center;
  }
</style>
