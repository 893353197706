<template>
      <div class="mt-3">
          <div v-for="policy of policyOptions" :key="policy.label">
            <div v-if="!excludeTypes.includes(policy.label)">
                <h5>{{policy.label}} Holdings</h5>
                <div class="card">
                    <div class="flex align-items-center py-2">
                        <Checkbox style="margin-top:3px;" :id="policy.label" name="label" :value="policy.label" v-model="selectedPolicy" v-on:change="updatePolicyItems(policy.label)"/>
                        <label :for="policy.label" :class="[isSelected(policy.label) ? 'selected-label' : '']">All {{policy.label}} Holdings</label>
                    </div>
                    <div v-if="policy.items" class="flex flex-column policy-item-row">
                        <div v-for="item in policy.items" :key="item" class="flex align-items-center portfolio-item flex-grow-1">
                            <div class="portfolio-checkbox flex flex-grow-1 mt-2">
                                <Checkbox style="margin-top:3px;" :id="item.value" name="policy" :value="item.value" v-model="selectedPlan" />
                                <label :for="item.value" :class="[isSelected(item.value) ? 'selected-label' : '']">{{item.label}} <span v-html="ownershipStringForUUID(item.value)"></span></label>
                            </div>
                            <div class="ml-3 value-text flex"><currency-value :value="getTotal(item.value)" :decimal-places="2" /></div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import CurrencyValue from "@/components/common/CurrencyValue";

import api from '@/core/services/api'
import { toDrupalRequest } from '@/core/helpers';
import dayjs from 'dayjs'

export default {
    props: {
      selectedPolicyValue: {
        default() {
          return []
        },
        type: Array,
      },
      selectedPlanValue: {
        default() {
          return []
        },
        type: Array,
      },
      excludeTypes: {
        default() {
          return []
        },
        type: Array,
      },
    },
    setup(props, { emit }) {
        const store = useStore()

        const selectedPolicy = computed({
          get: () => props.selectedPolicyValue,
          set: (value) => {
            emit('update:selectedPolicyValue', value)
          }
        })

        const selectedPlan = computed({
          get: () => props.selectedPlanValue,
          set: (value) => {
            emit('update:selectedPlanValue', value)
          }
        })

        const planValues = ref([])

        const updatePolicyItems = (data) => {
            const inArray = !selectedPolicy.value.includes(data)
            inArray ? addItems(data) : removeItems(data)
        }

        const addItems = (data) => {
            const policy = policyOptions.value.filter(policy => policy.label == data)
            policy[0].items.forEach(plan => {
                const idx = selectedPlan.value.indexOf(plan.value)
                if(idx == -1) {
                    selectedPlan.value.push(plan.value)
                }
            });
        }

        const removeItems = (data) => {
            const policy = policyOptions.value.filter(policy => policy.label == data)
            policy[0].items.forEach(plan => {
                const idx = selectedPlan.value.indexOf(plan.value)
                if(idx !== -1) {
                  selectedPlan.value.splice(idx, 1)
                }
            })
        }

        const policyOptions = computed(() => {
            const stateOptions = store.state.selections.policyOptions;
            if(stateOptions && stateOptions.error){
                store.dispatch('addNotification', {severity: 'error', summary: 'Policy Select', detail: stateOptions.message })
                return [];
            }else{
                const options = store.getters.fullPolicyOptions;
                options.forEach(option => {
                    option.items.forEach(opt => getValue(opt.value))
                })
                return options
            }
        })

        const getValue = async (plan) => {
            const date = dayjs()
            const res = await api.get(`${toDrupalRequest('valuation/plan/summary/'+plan+'/'+date.format('YYYY-MM-DD'))}`)
            if(res) {
                planValues.value.push({uuid: plan, total: res.total})
            }
        }

        const getTotal = (uuid) => {
            const found = planValues.value.find(plan => plan.uuid == uuid)
            return found ? found.total : 0
        }

        const checkParent = (policy) => {
            //check if all of this type have been checked. If not uncheck the value.
            const options = policyOptions.value.filter(option => option.label == policy)
            // are all these options checked?
            let checked = 0;
            options[0].items.forEach(option => {
              if(selectedPlan.value.indexOf(option.value) !== -1){
                checked++
              }
            })
            if(checked == options[0].items.length){
              // make sure the value is checked.
              if(!selectedPolicy.value.includes(policy)){
                selectedPolicy.value.push(policy)
              }
            }else{
              // make sure the value is unchecked.
              const idx = selectedPolicy.value.indexOf(policy)
              // only splice if index found!
              if(idx !== -1) {
                selectedPolicy.value.splice(idx, 1)
              }
            }
        }


        const isSelected = (item) => {
           return selectedPlan.value.includes(item) || selectedPolicy.value.includes(item)
        }

        const getSelectedPlans = (plan) => {
          let rtnString = ''

          if (selectedPolicy.value.includes(plan)) {
            return rtnString += `All ${plan} Holdings`
          }

          const items = policyOptions.value[policyOptions.value.findIndex(policy => policy.label == plan)].items
          const plans = []
          items.forEach(item => {
            if (selectedPlan.value.includes(item.value)) {
              plans.push(item.label)
            }
          })

          if(plans.length) {
            return rtnString += plans.join(', ')
          }

          return 'None'
        }

        const emitData = () => {

          // when policy options changes check the parents to make sure they are checked accordingly following the update
          policyOptions.value.forEach((option) => {
            checkParent(option.label);
          })

          // calculate and emit the html for the selected holdings.
          let content = ''
          policyOptions.value.forEach((option) => {
            content += '<div class="flex align-items-baseline">'
            content += '<label><b>'+option.label+' Holdings:</b></label>'
            content += '<span>&nbsp;'+getSelectedPlans(option.label)+'</span>'
            content += '</div>'
          })
          emit('selectedPlanContent', content)

          // calculate and emit total value selected.
          let totalSelected = 0;
          selectedPlan.value.forEach(async (plan) => {
            const valueData = planValues.value.find(obj => {
              return obj.uuid === plan
            })
            totalSelected += valueData.total;
          })
          emit('updateTotalValue', totalSelected);

        }

        watch(selectedPlan, () => {
          emitData()
        });

        watch(selectedPolicy, () => {
          emitData()
        })

        const ownershipStringForUUID = (uuid) => {
            const basicInfo = store.getters.policyBasicInfo(uuid);
            if (basicInfo && basicInfo.ownership) {
                let rtn = []
                basicInfo.ownership.forEach(person => {
                    rtn.push(store.getters.personFromUuid(person).name)
                })
                return '(<span style="font-weight: bold">'+rtn.join(", ")+'</span>)'
            }
            return '';
        }

        return {
            selectedPlan,
            selectedPolicy,
            updatePolicyItems,
            policyOptions,
            peopleSelected: computed(() => store.getters.peopleSelected),
            getTotal,
            planValues,
            checkParent,
            isSelected,
            ownershipStringForUUID
        }
    },
    components: {
        CurrencyValue
    }
}
</script>
<style scoped>
.title {
  padding: 30px;
  padding-bottom: 0px;
}
div:deep(.modal-close) {
    display: none;
}
.card {
    border-radius: 27px;
    border: 1px solid var(--clarity-light-grey);
    box-shadow: none;
}
.card label {
  color: var(--clarity-blue);
  font-weight: bold;
  font-size: 18px;
}
div:deep(.p-checkbox) {
    margin-right: 15px;
    border-radius: 2px;
}
div:deep(.p-checkbox-box) {
    border-radius: 0px;
}
.filters {
    width: 100%;
}
.filters > div {
    width: 50%;
}
.filters label {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
}
.overview {
    font-size: 18px;
}
.creation-summary {
    padding-left: 30px;
}
.creation-summary label {
    font-weight: bold;
    margin-right: 5px;
}
.selected-label {
  color: var(--clarity-blue);
  font-weight: bold;
}
button.clarity-btn {
  padding: 15px 45px;
}
.policy-item-row {
  margin-left: 33px;
}
.value-text {
  width: 30%;
  font-size: 18px;
  color: var(--clarity-dark-grey);
  text-align: right;
}
</style>
