<template>
  <div>
    <div class="flex flex-column">
      <h3 class="mb-3">What will my investments be worth?</h3>
      <div class="flex align-items-center justify-content-between">
        <div class="mr-5">
          <div class="card-body">Project how your savings could grow based on current fund value, regular savings and target annual growth rate.</div>
        </div>
      </div>
    </div>

    <spacer-h />

    <div class="flex">
      <div class="flex-1 mr-3">
        <div class="flex mb-3" style="min-height:39px">
          <div class="flex align-items-center justify-content-start">
            <h3 style="margin-top:6px">INVESTMENT DETAILS</h3>
          </div>
          <div class="flex-grow-1"></div>
          <div class="flex justify-content-end">
            <div class="flex align-items-center">
              <Button class="load-portfolio-btn" @click="openModal">Load Portfolio Values</Button>
              <load-portfolio-values-modal v-show="showModal === 'loadPortfolioValues'" @setValue="updateTestLoadValue"/><br />
            </div>
          </div>
        </div>
        <div class="round-wrapper">
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
                <div>
                  <h5 class="dark-grey">Current Value of Funds:</h5>
                  <p class="small-text grey">Enter the value of any existing investments you would like to be included in the calculation.</p>
                </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber @focus="$event.target.select()" v-model="currentFundValue" mode="currency" :currency="userCurrencyValue" :min="0" :maxFractionDigits="2" class="calc-input" style="width: 200px;" />
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                <h5 class="dark-grey">Investment Timeframe:</h5>
                <p class="small-text grey">The length of time that you plan to invest for your investments (express in years).</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber v-model="investmentTimeframe" mode="decimal" showButtons suffix=" years" :min="1" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                <h5 class="dark-grey">Regular Savings Amount:</h5>
                <p class="small-text grey">Enter any regular savings amount. See the notes for an explanation of how this can affect the calculations.</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber @focus="$event.target.select()" v-model="regularSavingsAmount" mode="currency" :currency="userCurrencyValue" :min="1" :maxFractionDigits="2" class="calc-input" style="width: 200px;" />
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                <h5 class="dark-grey">Payment Freqency:</h5>
                <p class="small-text grey">Choose the frequency for your payments (Weekly, Monthly or Annual).</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <Dropdown v-model="paymentFrequency" :options="frequencyOptions" optionLabel="name" option-value="value" class="calc-input" style="width: 200px"/>
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                <h5 class="dark-grey">Savings Timeframe:</h5>
                <p class="small-text grey">For how long will you be adding funds?</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber v-model="savingsTimeframe" mode="decimal" showButtons suffix=" years" :min="1" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                <h5 class="dark-grey">Increase Savings By:</h5>
                <p class="small-text grey">The percentage that your savings will increase by each year.</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber @focus="$event.target.select()" v-model="increaseSavingsBy" mode="decimal" showButtons suffix="%" :min="0" :step="0.25" :minFractionDigits="2" :maxFractionDigits="2" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                  <h5 class="dark-grey">Growth Rate Assumption:</h5>
                  <p class="small-text grey">Percentage that your investments are assumed to grow by each year net of charges. Default assumption is 4.5%.</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber @focus="$event.target.select()" v-model="growthRateAssumption" mode="decimal" showButtons suffix="%" :min="-50" :step="0.25" :minFractionDigits="2" :maxFractionDigits="2" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
            </div>
          </div>
          <div class="flex align-items-stretch">
            <div class="flex flex-grow-1 align-items-center justify-content-start m-2 mr-4">
              <div>
                  <h5 class="dark-grey">Inflation Rate Assumption:</h5>
                  <p class="small-text grey">Used to discount the projected value back to Today’s Terms. Default Assumption is 2.5%.</p>
              </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center m-2 input-wrapper">
              <InputNumber @focus="$event.target.select()" v-model="inflationRateAssumption" mode="decimal" showButtons suffix="%" :min="-50" :step="0.25" :minFractionDigits="2" :maxFractionDigits="2" class="calc-input" inputClass="investmentCalculatorInputformat" incrementButtonClass="investmentCalculatorInputBtnUp" decrementButtonClass="investmentCalculatorInputBtnDown" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 ml-3">
        <div class="flex mb-3" style="min-height:39px">
          <div class="flex align-items-center justify-content-start">
            <h3 style="margin-top:6px">RESULTS</h3>
          </div>
          <div class="flex-grow-1"></div>
          <div class="flex justify-content-end">
            <div class="flex align-items-center">
              <p class="mb-0 mr-2">Projected Value:</p>
            </div>
            <div class="flex align-items-center">
              <Dropdown v-model="projectedValue" :options="projectedValueOptions" optionLabel="name" option-value="value" class="projected-value-select"/>
            </div>
          </div>
        </div>
        <div class="round-wrapper">
          <div class="flex flex-column align-items-center">
            <div v-if="projectedValue === 0">
              <h5 class="mb-3">Today’s Terms Value:</h5>
            </div>
            <div v-else>
              <h5 class="mb-3">Nominal Terms Value:</h5>
            </div>
            <h3 class="blue"><currency-value :value="todayTermsValue" :decimal-places="2" /></h3>
            <p v-if="projectedValue === 0" class="small-text grey center-text">Refers to the value of your investments at the end of the period discounted for inflation (see notes for more explanation).</p>
            <p v-else class="small-text grey center-text">Remember the buying power of the fund will be reduced by inflation. Switch to today's terms to see the value after allowing for inflation.</p>
          </div>
          <div v-if="todayTermsValue > 0">
            <what-will-my-investments-be-worth-chart :chart-data="investmentWorthChart" :bar-width="40" />
          </div>
          <div v-else>
            <Skeleton width="100%" height="700px" animation="none" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpacerH from '@/components/common/layout/SpacerH.vue';
import {computed, ref} from 'vue';
import CurrencyValue from "../common/CurrencyValue";
import WhatWillMyInvestmentsBeWorthChart from '../charts/WhatWillMyInvestmentsBeWorthChart';
import {useStore} from "vuex";
import LoadPortfolioValuesModal from "../modals/LoadPortfolioValuesModal";


export default {
  name: "WhatWillMyInvestmentsBeWorth",
  setup() {
      const store = useStore();

      const currentFundValue = ref(store.state.calculators.startingValue);
      const investmentTimeframe = ref(store.state.calculators.projectionYears);
      const regularSavingsAmount = ref(store.state.calculators.regularSavings);
      const savingsTimeframe = ref(store.state.calculators.savingsTimeframe);
      const userCurrencyValue = ref(store.getters.currencySelected);
      const paymentFrequency = ref(12);
      const frequencyOptions = [
        {name: "Weekly", value: 52},
        {name: "Monthly", value: 12},
        {name: "Annual", value: 1},
      ]
      const projectedValue = ref(0);
      const projectedValueOptions = [
        {name: "Today's Terms", value: 0},
        {name: "Nominal Terms", value: 1}
      ]
      const increaseSavingsBy = ref(store.state.calculators.savingsRate);
      const growthRateAssumption = ref(store.state.calculators.growthRate);
      const inflationRateAssumption = ref(store.state.calculators.inflationRate);

      const todayTermsValue = computed( () => {
        const yearsWorth = [];
        let returnValue = 0.00;
        if(paymentFrequency.value === 1) {
          for(let x=1;x<=investmentTimeframe.value;x++) {
            if(x===1) {
              const totalToGrow = currentFundValue.value + regularSavingsAmount.value;
              const growthValue = totalToGrow * (growthRateAssumption.value / 100);
              const yearEndBalance = totalToGrow + growthValue;
              yearsWorth.push({year: x, balance: yearEndBalance, savingsAmount: regularSavingsAmount.value, growthValue:growthValue});
            } else {
              let updatedSavingsValue = 0;
              if(x<=savingsTimeframe.value) {
                const savingsGrowthValue = yearsWorth[x-2].savingsAmount * (increaseSavingsBy.value / 100);
                updatedSavingsValue = yearsWorth[x-2].savingsAmount + savingsGrowthValue;
              } else {
                updatedSavingsValue = 0;
              }
              const totalToGrow = yearsWorth[x-2].balance + updatedSavingsValue;
              const growthValue = totalToGrow * (growthRateAssumption.value / 100);
              const yearEndBalance = totalToGrow + growthValue;
              yearsWorth.push({year: x, balance: yearEndBalance, savingsAmount: updatedSavingsValue, growthValue:growthValue});
            }
          }
          if(yearsWorth.length>0) {
            if(projectedValue.value === 0) {
              returnValue = yearsWorth[investmentTimeframe.value-1].balance * (Math.pow((1-inflationRateAssumption.value/100),investmentTimeframe.value));
            } else if(projectedValue.value === 1) {
              returnValue = yearsWorth[investmentTimeframe.value-1].balance;
            }
          }
        } else {
          // monthly calcs
          const investmentTimeframeMonth = investmentTimeframe.value * paymentFrequency.value;
          for(let x=1;x<=investmentTimeframeMonth;x++) {
            const growthRateAssumptionMonthly = growthRateAssumption.value / paymentFrequency.value;
            if(x===1) {
              const totalToGrow = currentFundValue.value + regularSavingsAmount.value;

              const growthValue = totalToGrow * (growthRateAssumptionMonthly / 100);
              const endBalance = totalToGrow + growthValue;
              yearsWorth.push({month: x, balance: endBalance, savingsAmount: regularSavingsAmount.value, growthValue:growthValue});
            } else {
              let updatedSavingsValue = 0;
              const savingsTimeframeMonth = savingsTimeframe.value * paymentFrequency.value;
              if(x<=savingsTimeframeMonth) {
                const increaseSavingsByMonthly = increaseSavingsBy.value / paymentFrequency.value;
                const savingsGrowthValue = yearsWorth[x-2].savingsAmount * (increaseSavingsByMonthly / 100);
                updatedSavingsValue = yearsWorth[x-2].savingsAmount + savingsGrowthValue;
              } else {
                updatedSavingsValue = 0;
              }
              const totalToGrow = yearsWorth[x-2].balance + updatedSavingsValue;
              const growthValue = totalToGrow * (growthRateAssumptionMonthly / 100);
              const endBalance = totalToGrow + growthValue;
              yearsWorth.push({month: x, balance: endBalance, savingsAmount: updatedSavingsValue, growthValue:growthValue});
            }
          }
          if(yearsWorth.length>0) {
            if(projectedValue.value === 0) {
              returnValue = yearsWorth[investmentTimeframeMonth-1].balance * (Math.pow((1-inflationRateAssumption.value/100),investmentTimeframe.value));
            } else if(projectedValue.value === 1) {
              returnValue = yearsWorth[investmentTimeframeMonth-1].balance;
            }
          }
        }
        return returnValue;
      });

      const investmentWorthChart = computed( () => {
        let returnValue = [];
        const graphData = [];
        let investmentPeriod = 0;
        let savingsPeriod = 0;
        if(paymentFrequency.value === 1) {
          investmentPeriod = investmentTimeframe.value;
          savingsPeriod = savingsTimeframe.value;
        } else if(paymentFrequency.value === 12) {
          investmentPeriod = investmentTimeframe.value * 12;
          savingsPeriod = savingsTimeframe.value * 12;
        } else if(paymentFrequency.value === 52) {
          investmentPeriod = investmentTimeframe.value * 52;
          savingsPeriod = savingsTimeframe.value * 52;
        }

        for(let x=1;x<=investmentPeriod;x++) {
          if(x===1) {
            if(projectedValue.value === 1) {
              const balance = currentFundValue.value;
              const savingsAmount = regularSavingsAmount.value;
              const totalToGrow = balance + savingsAmount;
              const growthValue = totalToGrow * ((growthRateAssumption.value / paymentFrequency.value) / 100);
              const endBalance = totalToGrow + growthValue;
              const cumulativeGrowth = growthValue;
              const totalInvested =  endBalance - cumulativeGrowth;
              const totalCheck = totalInvested + cumulativeGrowth;

              graphData.push({month: x, balance: balance, savingsAmount: savingsAmount, totalToGrow: totalToGrow, growthValue: growthValue, endBalance: endBalance, cumulativeGrowth: cumulativeGrowth, totalInvested: totalInvested, totalCheck: totalCheck, adjustedGrowthValue: null, adjustedTotalInvested: null, adjustedTotalCheck: null});

            } else if(projectedValue.value === 0) {
              const balance = currentFundValue.value;
              const savingsAmount = regularSavingsAmount.value;
              const totalToGrow = balance + savingsAmount;
              const growthValue = totalToGrow * ((growthRateAssumption.value / paymentFrequency.value) / 100);
              const endBalance = totalToGrow + growthValue;
              const cumulativeGrowth = growthValue;
              const totalInvested =  endBalance - cumulativeGrowth;
              const totalCheck = totalInvested + cumulativeGrowth;

              const adjustedGrowthValue = growthValue * (Math.pow((1-inflationRateAssumption.value/100),1/paymentFrequency.value));
              const adjustedTotalInvested = totalInvested * (Math.pow((1-inflationRateAssumption.value/100),1/paymentFrequency.value));
              const adjustedTotalCheck = totalCheck * (Math.pow((1-inflationRateAssumption.value/100),1/paymentFrequency.value)) ;

              graphData.push({month: x, balance: balance, savingsAmount: savingsAmount, totalToGrow: totalToGrow, growthValue: growthValue, endBalance: endBalance, cumulativeGrowth: cumulativeGrowth, totalInvested: totalInvested, totalCheck: totalCheck, adjustedGrowthValue: adjustedGrowthValue, adjustedTotalInvested: adjustedTotalInvested, adjustedTotalCheck: adjustedTotalCheck});
            }
          } else {
            if(projectedValue.value === 1) {
              let updatedSavingsValue = 0;
              if(x<=savingsPeriod) {
                const savingsValueGrowth = graphData[x-2].savingsAmount * ((increaseSavingsBy.value / paymentFrequency.value )/ 100);
                updatedSavingsValue = savingsValueGrowth + graphData[x-2].savingsAmount;
              }

              const balance = graphData[x-2].endBalance;
              const savingsAmount = updatedSavingsValue;
              const totalToGrow = balance + savingsAmount;
              const growthValue = totalToGrow * ((growthRateAssumption.value / paymentFrequency.value) / 100);
              const endBalance = totalToGrow + growthValue;
              const cumulativeGrowth = graphData[x-2].cumulativeGrowth + growthValue;
              const totalInvested =  endBalance - cumulativeGrowth;
              const totalCheck = totalInvested + cumulativeGrowth;

              graphData.push({month: x, balance: balance, savingsAmount: savingsAmount, totalToGrow: totalToGrow, growthValue: growthValue, endBalance: endBalance, cumulativeGrowth: cumulativeGrowth, totalInvested: totalInvested, totalCheck: totalCheck, adjustedGrowthValue: null, adjustedTotalInvested: null, adjustedTotalCheck: null});
            } else if(projectedValue.value === 0) {
              let updatedSavingsValue = 0;
              if(x<=savingsPeriod) {
                const savingsValueGrowth = graphData[x-2].savingsAmount * ((increaseSavingsBy.value / paymentFrequency.value )/ 100);
                updatedSavingsValue = savingsValueGrowth + graphData[x-2].savingsAmount;
              }

              const balance = graphData[x-2].endBalance;
              const savingsAmount = updatedSavingsValue;
              const totalToGrow = balance + savingsAmount;
              const growthValue = totalToGrow * ((growthRateAssumption.value / paymentFrequency.value) / 100);
              const endBalance = totalToGrow + growthValue;
              const cumulativeGrowth = graphData[x-2].cumulativeGrowth + growthValue;
              const totalInvested =  endBalance - cumulativeGrowth;
              const totalCheck = totalInvested + cumulativeGrowth;

              const adjustedGrowthValue = cumulativeGrowth * (Math.pow((1-inflationRateAssumption.value/100),x/paymentFrequency.value));
              const adjustedTotalInvested = totalInvested * (Math.pow((1-inflationRateAssumption.value/100),x/paymentFrequency.value));
              const adjustedTotalCheck = totalCheck * (Math.pow((1-inflationRateAssumption.value/100),x/paymentFrequency.value)) ;

              graphData.push({month: x, balance: balance, savingsAmount: savingsAmount, totalToGrow: totalToGrow, growthValue: growthValue, endBalance: endBalance, cumulativeGrowth: cumulativeGrowth, totalInvested: totalInvested, totalCheck: totalCheck, adjustedGrowthValue: adjustedGrowthValue, adjustedTotalInvested: adjustedTotalInvested, adjustedTotalCheck: adjustedTotalCheck});
            }
          }
        }
        //console.log(graphData);
        if(paymentFrequency.value === 1) {
          if(projectedValue.value === 1) {
            for(let x=1;x<=investmentTimeframe.value;x++) {
              returnValue.push({year: x, balance:graphData[x-1].totalInvested, savingsAmount: graphData[x-1].savingsAmount, growthValue: graphData[x-1].cumulativeGrowth});
            }
          } else if (projectedValue.value === 0) {
            for(let x=1;x<=investmentTimeframe.value;x++) {
              returnValue.push({year: x, balance:graphData[x-1].adjustedTotalInvested, savingsAmount: graphData[x-1].savingsAmount, growthValue: graphData[x-1].adjustedGrowthValue});
            }
          }
        } else if (paymentFrequency.value === 12) {
          let year = 1;
          graphData.forEach(element => {
            const modulo = element.month % 12;
            if(modulo === 0) {
              if(projectedValue.value === 1) {
                returnValue.push({year: year, balance:element.totalInvested, savingsAmount: element.savingsAmount, growthValue: element.cumulativeGrowth});
              } else if (projectedValue.value === 0) {
                returnValue.push({year: year, balance:element.adjustedTotalInvested, savingsAmount: element.savingsAmount, growthValue: element.adjustedGrowthValue});
              }
              year++;
            }
          });
        } else if (paymentFrequency.value === 52) {
          let year = 1;
          graphData.forEach(element => {
            const modulo = element.month % 52;
            if(modulo === 0) {
              if(projectedValue.value === 1) {
                returnValue.push({year: year, balance:element.totalInvested, savingsAmount: element.savingsAmount, growthValue: element.cumulativeGrowth});
              } else if (projectedValue.value === 0) {
                returnValue.push({year: year, balance:element.adjustedTotalInvested, savingsAmount: element.savingsAmount, growthValue: element.adjustedGrowthValue});
              }
              year++;
            }
          });
        }
        return returnValue;
      });

      const openModal = () => {
        store.dispatch('setActiveModal', {modal: 'loadPortfolioValues'});
      }

      const showModal = computed(() => store.getters.currentActiveModal);

      const updateTestLoadValue = (value) => {
        currentFundValue.value = value;
    }

    return {
      currentFundValue,
      investmentTimeframe,
      regularSavingsAmount,
      savingsTimeframe,
      paymentFrequency,
      frequencyOptions,
      projectedValueOptions,
      projectedValue,
      increaseSavingsBy,
      growthRateAssumption,
      inflationRateAssumption,
      investmentWorthChart,
      todayTermsValue,
      showModal,
      openModal,
      updateTestLoadValue,
      userCurrencyValue
    }
  },
  components: {
    WhatWillMyInvestmentsBeWorthChart,
    SpacerH,
    CurrencyValue,
    LoadPortfolioValuesModal
  }
}
</script>

<style scoped>
  .round-wrapper {
    min-height: 1084px;
    background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
    border-radius: 27px;
    opacity: 1;
    padding: 30px;
  }
  .input-wrapper {
    width: 200px;
  }
  .projected-value-select {
    height: 30px;
  }
  .center-text {
    text-align: center;
  }
  .load-portfolio-btn {
    min-width: 161px;
    height: 30px;
    padding: 6px 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    color: var(--clarity-dark-grey);
    box-shadow: 0px 5px 10px #0000001A;
    background: transparent linear-gradient(180deg, var(--clarity-snow-grey) 0%, var(--clarity-light-grey) 100%) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
  }
  .load-portfolio-btn:hover {
    background: transparent linear-gradient(180deg, var(--clarity-light-grey) 0%, var(--clarity-snow-grey) 100%) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
    color: var(--clarity-dark-grey);
  }
</style>
