<template>
      <div ref="howmuchdoineedtoinvestchart" class="need-to-invest-chart">

      </div>
</template>

<script>
    import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import {ref, watch, toRef, onMounted} from 'vue'
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    export default {
      name: 'HowMuchDoINeedToInvestChart',
      props: {
        chartData: Object,
        barWidth: Number
      },
      setup(props) {

        const series = ref([]);
        const xAxis = ref([]);
        const chartRendered = ref(false);
        const howmuchdoineedtoinvestchart = ref(null);
        const exporting = ref();

        const chartData = toRef(props, 'chartData')
        const barWidth = toRef(props, 'barWidth')


        onMounted(() => {
          if(chartData.value) {
            renderChart(series, chartData.value)
            chartRendered.value = true;
          }
        })

        watch(chartData, (value) => {
          if(chartRendered.value){
            updateChart(series, value)
          }else{
            renderChart(series, value)
            chartRendered.value = true;
          }
        })

        const renderChart = (series, data) => {

          am5.addLicense("AM5C334338446");

          let root = am5.Root.new(howmuchdoineedtoinvestchart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          const numberFormat = {
            numberFormat: "#.#a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
              { "number": 1e+3, "suffix": "K" },
              { "number": 1e+6, "suffix": "M" },
              { "number": 1e+9, "suffix": "B" }
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: []
          }

          root.numberFormatter.setAll(numberFormat);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/xy-chart/
          var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout
          }));

          chart.get("colors").set("colors", [
            am5.color("#179BD7"),
            am5.color("#F18903")
          ]);

          const linearGradient = am5.LinearGradient.new(root, {
            stops: [{
              opacity: 1
            }, {
              opacity: 0.2
            }],
            rotation: 90
          })

          // Create axes
          // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
          xAxis.value = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "year",
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {}),
          }));

          xAxis.value.data.setAll(data);

          var yRenderer = am5xy.AxisRendererY.new(root, {})

          var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: yRenderer,
            //spacing at the top of the chart
            extraMax: 0.1,
          }));

          // Add legend
          // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
          var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.percent(56),
          }));

          legend.labels.template.setAll({
            fontSize: 14,
            fontWeight: "400",
            marginLeft: 18,
            centerY: 6
          });

          legend.markerRectangles.template.setAll({
            cornerRadiusTL: 15,
            cornerRadiusTR: 15,
            cornerRadiusBL: 15,
            cornerRadiusBR: 15,
            width: 25,
            height: 25
          });

          // Add series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          function makeSeries(name, fieldName) {
            series.value[fieldName] = chart.series.push(am5xy.ColumnSeries.new(root, {
              name: name,
              xAxis: xAxis.value,
              yAxis: yAxis,
              valueYField: fieldName,
              categoryXField: "year"
            }));

            series.value[fieldName].columns.template.setAll({
              tooltipText: "{name}: {valueY}",
              width: am5.percent(95),
              tooltipY: 0,
              cornerRadiusTL: barWidth.value/2,
              cornerRadiusTR: barWidth.value/2,
              maxWidth: barWidth.value
            });

            series.value[fieldName].columns.template.set("fillGradient", linearGradient);
            series.value[fieldName].columns.template.set("strokeOpacity", 0);


            series.value[fieldName].data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.value[fieldName].appear();

            /*
            series.value[fieldName].bullets.push(function () {
              return am5.Bullet.new(root, {
                locationY: 1,
                sprite: am5.Label.new(root, {
                  text: "{valueY}",
                  centerX: am5.p50,
                  dy: -30,
                  scale: 0.8,
                  populateText: true
                })
              });
            });

             */

            legend.data.push(series.value[fieldName]);
          }

          makeSeries('Projected Balance', 'balance');

          // Make stuff animate on load
          // https://www.amcharts.com/docs/v5/concepts/animations/
          chart.appear(1000, 100);

          exporting.value = am5plugins_exporting.Exporting.new(root, {});

        }

        const updateChart = (series, data) => {

          xAxis.value.data.setAll(data);
          series.value['balance'].data.setAll(data);

        }

        return {
          series,
          chartRendered,
          howmuchdoineedtoinvestchart,
        }
      }
    }
</script>

<style scoped lang="scss">
    .need-to-invest-chart {
      position: relative;
      width: 100%;
      height: 600px;
    }

</style>
