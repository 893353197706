<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Investment Calculator</h2></template>
        <template #content>
              <span @click="$router.back()" style="cursor: pointer;">
                <span class="material-icons-round" style="vertical-align: middle;">chevron_left</span>Back to Calculators
              </span>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-column">
        <h3 class="mb-3">Welcome to the Investment Calculator</h3>
        <div class="flex align-items-center justify-content-between">
          <div class="mr-5">
            <div class="card-body">Use the investment calculator to project the growth of your current savings or to calculate how much you need to save to reach your investment goals.<br>Our research library provides research notes with further information about investment.</div>
          </div>
        </div>
      </div>

      <spacer-h />

      <TabView ref="investmenttab" v-model:activeIndex="active">
        <TabPanel>
          <template #header>
            <h3>What will my investments be worth?</h3>
          </template>
          <WhatWillMyInvestmentsBeWorth />
        </TabPanel>
        <TabPanel>
          <template #header>
            <h3>How much do I need to invest?</h3>
          </template>
          <how-much-do-i-need-to-invest />
        </TabPanel>
      </TabView>
      <spacer-h />
      <div class="flex flex-column">
        <h3 class="mb-3">REFERENCE</h3>
        <h4 class="my-2 blue">DISCLAIMER</h4>
        <p class="small-text mb-3">This calculator is to be considered as a guide only and makes assumptions which need to be referred to. Specifically, any assumed growth rates and investment returns may not be achieved. Applicable tax rates and relevant legislation are correct as at February 2023 but may be subject to change. It does not represent personalised advice.</p>
        <h4 class="mb-3 blue">ASSUMPTIONS</h4>
        <ul>
          <li>Calculations assume that all premiums are made at the beginning of the payment period.</li>
          <li>Interest is accrued per payment period and then applied annually. Annual premiums will receive a full years growth in the year of investment and this will be applied to the balance at the end of the year. Monthly/Weekly premiums will accrue interest from the date of investment so the first payment will receive a years growth, the second payment 11/12 and so on.</li>
          <li>Savings Increases are applied annually so with a monthly saving of £100 increasing by 6% per annum the calculations assume 12 payments of £100 in the first year and then twelve payments of £106 in the second year.</li>
        </ul>
        <h4 class="mb-3 blue">TODAY'S TERMS/TOMORROW'S TERMS?</h4>
        <p class="small-text mb-3">When making any investments, it is important to understand whether you should be saving with today's terms or tomorrow's terms in mind. For example, if you are investing in order to buy a car in 5 year's time and today that car costs £10,000, you will need to save more than £10,000 in order to buy it as it will cost more (due to the effect of inflation) then. Therefore you need to look at <b>Today's Terms</b> to understand if your investments will cover your need. If you are saving to cover a fixed purchase eg a mortgage, a loan etc, you need to look at <b>Tomorrow's Terms</b> as the amount that you are saving for will not be affected by inflation.</p>
        <h4 class="mb-3 blue">THE FCA GROWTH RATES</h4>
        <p class="small-text">The FCA growth rates, before charges are taken into account, are:</p>
        <ul>
          <li>for untaxed products (for example, pensions and ISAs): low return is 2%, medium is 5% and and a high return is 8%.</li>
          <li>for taxed products (for example, collective investment schemes and mortgage endowments): low return is 1.5%, medium is 4.5% and a high return is 7.5%.</li>
        </ul>
        <h4 class="mb-3 blue">INVESTMENT AND ASSET ALLOCATION</h4>
        <p class="small-text mb-3">At clarity our investment philosophy is based upon the belief that asset allocation is the main driver of long term investment performance. Please see the asset allocation research note for more information.</p>
        <h4 class="mb-3 blue">INVESTMENT AND ATTITUDE</h4>
        <p class="small-text mb-3">Any investment decisions should always be taken with your attitude to risk in mind. Please see our risk profiler for more information.</p>
        <h4 class="mb-3 blue">OTHER RESOURCES</h4>
        <div id="research-note-links" class="flex flex-wrap">
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/sites/default/files/research/notes/unit_trusts.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Unit Trusts, OEICs and ICVCs</a></div>
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/sites/default/files/research/notes/quickguide_isa.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">ISAs and PEPs</a></div>
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/sites/default/files/research/notes/investmentcompanies.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Investment Companies</a></div>
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/sites/default/files/research/notes/asset_allocation_risk_return.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Asset Allocation - Risk and Return</a></div>
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/sites/default/files/research/notes/with_profits_funds.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">With Profits Funds</a></div>
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/esg'"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Ethical Funds</a></div>
          <div class="flex calc-note align-items-start"><a :href="clarityURL+'/sites/default/files/research/notes/structured_products.pdf'" target="_blank"><img style="vertical-align:middle;" src="../../assets/icons/pdf-icon.svg">Structured Products</a></div>
        </div>
      </div>
    </template>
  </content-card-full>
</template>

<script>
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import WhatWillMyInvestmentsBeWorth from "./WhatWillMyInvestmentsBeWorth";
//import LoadPortfolioValuesModal from "../modals/LoadPortfolioValuesModal";

import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import HowMuchDoINeedToInvest from "./HowMuchDoINeedToInvest";


export default {
  name: "InvestmentCalculator",
  setup() {

    const store = useStore()

    const testLoadValue = ref(0);
    const clarityURL = process.env.VUE_APP_CLARITYURL;


    onMounted(() => {
      // this needs/should be done elsewhere -> awating menu/pages to make use of prior loaded data
      // this view currently works in isolation
      store.dispatch('getPortfolioOptions')
      store.dispatch('getPolicyOptions')
    })

    const openModal = () => {
      store.dispatch('setActiveModal', {modal: 'loadPortfolioValues'})
    }

    const showModal = computed(() => store.getters.currentActiveModal)

    const updateTestLoadValue = (value) => {
      testLoadValue.value = value;
    }

    return {
      active: 0,
      openModal,
      showModal,
      testLoadValue,
      updateTestLoadValue,
      clarityURL
    }
  },
  components: {
    HowMuchDoINeedToInvest,
    WhatWillMyInvestmentsBeWorth,
    ContentCardFull,
    CardTitle,
    SpacerH,
    //LoadPortfolioValuesModal
  }
}
</script>

<style scoped>
  #research-note-links a {
    color: var(--clarity-dark-grey);
  }
  .p-tabview :deep(.p-tabview-nav) {
    background: unset;
    border: none;
  }
  .p-tabview :deep(.p-tabview-nav-container) {
    margin: 0px 30px;
  }
  .p-tabview :deep(.p-tabview-panels) {
    padding: unset;
    background: var(--clarity-snow-grey);
  }
  .p-tabview :deep(.p-tabview-nav li .p-tabview-nav-link) {
    background: var(--clarity-snow-grey);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 75px;
    min-width: 280px;
    color: var(--clarity-dark-grey);
    padding-right: 45px;
    padding-left: 45px;
  }
  .p-tabview :deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    background: var(--clarity-pure-white);
    color: var(--clarity-blue);
    border: 1px solid var(--clarity-light-grey);
    box-shadow: 0px 5px 20px #0000000D;
    border-radius: 27px 27px 0px 0px;
  }
  .p-tabview :deep(.p-tabview-ink-bar) {
    background-color: var(--clarity-blue);
    height: 5px;
    border-radius: 4px 4px 0px 0px;
    padding-right: 45px;
    padding-left: 45px;
  }

  .p-tabview :deep(.p-tabview-panels) {
    /* UI Properties */
    background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--clarity-light-grey);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DCE1E6;
    border-radius: 27px;
    opacity: 1;
    padding: 30px;
  }

  .calc-note {
      margin-right: 15px;
      margin-bottom: 15px;
  }

</style>
